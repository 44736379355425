import React from 'react'
import {
  CFImage,
  CFLink,
  CFText,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  about,
  aboutText,
  interior,
  mobileAbout,
  johnhouseHistory,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={interior} w="100%" alt="Sushi Oyama About" />
          <CFView>
            <CFView
              column
              center
              w="100%"
              p="20px"
              bg="black"
              style={{
                lineHeight: '30px',
              }}
            >
              <CFText raleway center h4 white bold>
                Sushi Oyama is a converted heritage house - The Andy Johnson
                'Glenedward' House . It is a valued representation of a
                prominent upper middle-class family dwelling from the pre-First
                World War era. Andrew M. Johnson (1861-1934), an early Vancouver
                pioneer, and his wife Margaret built this house in 1912, in an
                imposing style favoured by the newly wealthy of the prewar boom
                period.
              </CFText>
              <CFView mt="10px">
                <CFLink href={johnhouseHistory} newTab>
                  <CFText center raleway h4 bold color="#49abd3">
                    Read More...
                  </CFText>
                </CFLink>
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="550px"
          w="100%"
          image={`url(${interior}) center/ cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
          zIndex={90}
          column
          justifyCenter
          alignStart
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <CFView ml="5%">
            <CFView
              column
              justifyStart
              alignStart
              w="60%"
              p="25px"
              br="5px"
              style={{
                lineHeight: '40px',
                backgroundImage:
                  'linear-gradient(to right, rgba(0,0,0,1),rgba(0,0,0,0.8),rgba(0,0,0,0))',
              }}
            >
              <CFText h2 white bold>
                Sushi Oyama is a converted heritage house - The Andy Johnson
                'Glenedward' House . It is a valued representation of a
                prominent upper middle-class family dwelling from the pre-First
                World War era. Andrew M. Johnson (1861-1934), an early Vancouver
                pioneer, and his wife Margaret built this house in 1912, in an
                imposing style favoured by the newly wealthy of the prewar boom
                period.
              </CFText>
              <CFView mt="10px">
                <CFLink href={johnhouseHistory} newTab>
                  <CFText h2 bold color="#49abd3">
                    Read More...
                  </CFText>
                </CFLink>
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
