import React, { useState, useEffect } from 'react'
import { css } from 'emotion'
import { CFImage, CFView, PrimaryModal } from 'components'
import { notice } from 'images'
import { dispatch } from 'store'

export default () => {
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const welcomeMessage = dispatch.restaurant.getWelcomeMessage()
    if (welcomeMessage.title) {
      setShowModal(true)
    }
  }, [])

  const closeModal = () => {
    setShowModal(false)
    // localStorage.setItem('welcomeSeen', true)
  }

  return (
    <PrimaryModal
      title={dispatch.restaurant.getWelcomeMessage().title}
      isOpen={showModal}
      onRequestClose={closeModal}
    >
      <CFView className={styles.container}>
        <CFView column center w="100%" pb="10px" textCenter>
          <CFView h3 black textCenter ph="5%" pv="10px">
            {dispatch.restaurant.getWelcomeMessage().body}
          </CFView>
          <CFImage src={notice} w="95%"></CFImage>
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}

const styles = {
  container: css({
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '640px',
    '@media (max-width: 767px)': {
      width: '100%',
    },
  }),
}
